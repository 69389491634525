<template>
  <Row v-if="data" align="middle" :wrap="false" justify="space-between">
    <template v-for="item in data.actions">
      <i-col @click.stop v-if="item.confirm" :key="'confirm-' + item.value">
        <Poptip transfer confirm @on-ok="$emit('item-click', item)" :title="'确定' + item.label + '吗？'">
          <Button :size="size" type="text">{{ item.label }}</Button>
        </Poptip>
      </i-col>
      <i-col v-else :key="item.value">
        <Button @click.stop="$emit('item-click', item)" :size="size" type="text">{{ item.label }}</Button>
      </i-col>
    </template>
  </Row>
</template>

<script>
  export default {
    props: {
      status: { type: Number, default: null },
      size: { type: String, default: 'small' }
    },
    computed: {
      data () {
        const status = this.status
        return this.$store.getters.scmPlanStatusList.find(v => v.value === status)
      }
    }
  }
</script>